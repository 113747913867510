//
import cloneDeep from "lodash.clonedeep";
import set from "lodash.set";
import pickBy from "lodash.pickby";

export interface UseFilterState {
  filters: any;
  filtersChange: boolean;
  hasFilters: boolean;
  isVatIncluded: boolean;
}

const queryParams = (route: any): any => {
  if (route && route.fullPath.indexOf("?") > 1) {
    return route.fullPath
      .slice(Math.max(0, route.fullPath.indexOf("?") + 1))
      .split("&")
      .map((param: any) => param.split("="))
      .reduce((values: { [x: string]: string }, [key, value]: any) => {
        if (value) {
          values[key] = decodeURIComponent(value).replaceAll("+", " ");
        }
        return values;
      }, {});
  }
  return {};
};

const useFilters = () => {
  const route = useRoute();
  const router = useRouter();

  // Set the initial Filters
  const initialValues = { category: [], price: { from: null, to: null } };

  // Initial State
  const state = useState<UseFilterState>(`useFilters`, () => ({
    filters: cloneDeep(initialValues),
    filtersChange: false,
    hasFilters: false,
    // USE Dynamic composable when built
    isVatIncluded: false,
  }));

  const initSearch = () => {
    const queryParamsValues = queryParams(route);
    if (Object.keys(queryParamsValues).length > 0) {
      state.value.hasFilters = true;
    }
    const { from, to, category, ...rest } = queryParams(route);
    const updatedModel = cloneDeep(initialValues);

    set(updatedModel, "price.from", from);
    set(updatedModel, "price.to", to);

    if (category) {
      const map = category.split(",");
      set(updatedModel, "category", map);
    }

    Object.keys(rest).forEach((facetId) => {
      if (
        facetId.endsWith("clid") ||
        facetId === "branch" ||
        facetId === "wbraid" ||
        facetId.startsWith("fb") ||
        facetId.startsWith("f24") ||
        facetId.startsWith("utm") ||
        facetId.startsWith("q") ||
        facetId.startsWith("p") ||
        facetId === "xnpe_tifc" ||
        facetId.startsWith("sort_by") ||
        facetId.startsWith("sort_value") ||
        facetId.startsWith("gad") ||
        facetId.startsWith("srsltid")
      )
        return;
      const values = (rest[facetId] || "").split(",");
      set(updatedModel, facetId, values);
    });

    state.value.filters = updatedModel;
  };

  const updateRoute = () => {
    const params: any = {};

    Object.keys(state.value.filters).forEach((facetId) => {
      if (facetId === "price") {
        params.from = state.value.filters.price?.from || undefined;
        params.to = state.value.filters.price?.to || undefined;
      } else if (facetId === "category") {
        params[facetId] = state.value.filters[facetId].map((it: { id: any }) => it.id).join(",") || undefined;
        return;
      } else if (Object.keys(state.value.filters[facetId] || []).length > 0) {
        params[facetId] = state.value.filters[facetId].join(",");
      }
    });

    const filteredParams = pickBy(params, (v) => v !== undefined);
    router.push({ query: { q: route.query.q, ...filteredParams } });
  };

  const clearAll = () => {
    state.value.filters = cloneDeep(initialValues);
    state.value.filtersChange = true;
    updateRoute();
  };

  const resetFilters = () => {
    state.value.filters = cloneDeep(initialValues);
  };

  const change = (add: boolean, ...facets: [{ id: any; valueId?: any; value?: any }]) => {
    state.value.filtersChange = true;
    const updatedModel = { ...state.value.filters };
    if (facets.some((it) => it.id === "price")) {
      set(updatedModel, "price.from", undefined);
      set(updatedModel, "price.to", undefined);
    }
    facets.forEach((facet) => {
      // if true, add the id of the facet value to selected values in the model
      if (add) {
        if (facet.valueId) {
          set(updatedModel, `${facet.id}.${facet.valueId}`, facet.value);
        } else {
          const values = updatedModel[facet.id] || [];
          values.push(facet.value);

          set(updatedModel, facet.id, values);
        }
      } else {
        if (facet.valueId) {
          set(updatedModel, `${facet.id}.${facet.value}`, facet.value);
        } else {
          const values = (updatedModel[facet.id] || []).filter((it: any) => it !== facet.value);
          set(updatedModel, facet.id, values);
        }
      }
    });
    state.value.filters = updatedModel;
    updateRoute();
  };

  const clear = (filters: any) => {
    change(false, filters);
  };

  const convertToBRData = (): Array<any> => {
    const { filters, isVatIncluded } = state.value;
    const keys = Object.keys(filters);

    const data = keys
      .map((key) => {
        const filterValues = filters[key];

        if (key === "price" && (filterValues.from || filterValues.to)) {
          const id = isVatIncluded ? "price_inc_vat" : "price";
          const values = `[${filterValues.from || 0} TO ${filterValues.to || 99_999}]`;
          return { id, values };
        }

        if (key === "category") {
          return filterValues.map(({ name, id }: { name: string; id: string }) => ({ name, id }));
        }

        return { id: key, values: filterValues };
      })
      .filter((item) => item && item.values.length > 0);

    const includeAvailability = data.some((el) => el.id === "availability_flag" && el.values.length > 0);
    return includeAvailability ? data : [...data, { id: "availability_flag", values: ["1", "2", "4"] }];
  };

  return {
    initSearch,
    convertToBRData,
    change,
    clear,
    clearAll,
    resetFilters,
    ...toRefs(state.value),
  };
};

export default useFilters;
